import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";

import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";

import {
  getMeetingDetails,
  getMyUploads,
  handleAcceptDecline,
  joinMeeting,
} from "../../../redux/actions/meeting.action";
import { useNavigate, useParams } from "react-router-dom";
import DetailsComponent from "../../components/DetailsComponent/DetailsComponent";
import { formatDate, formattTime, isMeetingEnd } from "../../../utils/core";

const CmeRtmDetails = () => {
  const { id } = useParams();
  const [infoArray, setInfoArray] = useState([]);
  const [meetData, setMeetData] = useState({});
  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const meetingInfo = useSelector((state) => state.meeting.meetingDetails);

  const urlParams = new URLSearchParams(window.location.search);
  const associationMeeting = urlParams.get("association");

  const decrypted = CryptoJS.SHA1(
    process.env.REACT_APP_ASSOCIATION_SECRET
  ).toString();

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getMeetingDetails({ id: id }));
    }
  }, []);

  useEffect(() => {
    if (meetingInfo.length > 0) {
      let data = meetingInfo[0];
      setMeetData(data);
      setInfoArray([
        { name: "Speaker 1", value: data.speaker1 },
        { name: "Speaker 2", value: data.speaker2 },
        {
          name: "Date & Time",
          value: `${formatDate(data.meetingDate)} from ${formattTime(
            data.startTime
          )} to ${formattTime(data.endTime)}  `,
        },
      ]);
    }
  }, [meetingInfo]);

  const getButtonText = (id) => {
    if (currentUser == id) {
      return "Start";
    } else {
      return "Join";
    }
  };

  const handleActionButton = async (id) => {
    //  dispatch(joinMeeting({ id: id }, navigate));
    if (currentUser == id) {
      if (meetData.startUrl) {
        window.open(meetData.startUrl, "_self");
      }
    } else {
      if (meetData.joinUrl) {
        window.open(meetData.joinUrl, "_self");
      }
    }
  };

  const handleAcceptOrDecline = (data, status) => {
    let requestData = {
      status: status,
      meetingId: data?._id,
      userId: currentUser,
      ...data,
    };
    dispatch(handleAcceptDecline(requestData));
  };

  const renderFooterButton = () => {
    if (!isMeetingEnd(meetData?.endDate)) {
      if (currentUser == meetData?.user_details?._id) {
        if (meetData.isApprove === "1") {
          return (
            <button
              onClick={() => handleActionButton(meetData?.user_details?._id)}
              class="btn btn-primary btn-lg mb-2"
            >
              Start Meeting <i class="fa-solid fa-arrow-right"></i>
            </button>
          );
        } else {
          return <span class={`badge bg-cme mb-2`}>Pending Approval</span>;
        }
      } else {
        if (decrypted === associationMeeting) {
          return (
            <button
              onClick={() => handleActionButton(meetData?.user_details?._id)}
              class="btn btn-primary btn-lg mb-2"
            >
              Join Meeting <i class="fa-solid fa-arrow-right"></i>
            </button>
          );
        } else {
          let member = meetData?.members?.find(
            (member) => member.userId === currentUser
          );
          if (member?.isAccept === 0) {
            return (
              <>
                <button
                  className=" btn btn-success mb-2 me-1"
                  onClick={() => handleAcceptOrDecline(meetData, 1)}
                >
                  <i className="fa-solid fa-check me-1"></i> Accept
                </button>
                <button
                  className=" btn btn-danger mb-2  me-1"
                  onClick={() => handleAcceptOrDecline(meetData, 2)}
                >
                  <i className="fa-solid fa-times me-1"></i> Decline
                </button>
              </>
            );
          } else if (member?.isAccept === 1) {
            return (
              <button
                onClick={() => handleActionButton(meetData?.user_details?._id)}
                class="btn btn-primary btn-lg mb-2"
              >
                Join Meeting <i class="fa-solid fa-arrow-right"></i>
              </button>
            );
          } else {
            return <span class={`badge bg-cme mb-2`}>Declined</span>;
          }
        }
      }
    } else {
      return <span class={`badge bg-cme mb-2`}>Ended</span>;
    }
  };

  return (
    <Root>
      <Breadcrumb
        title={"CME (Continuing Medical Education)"}
        fieldOne="Dashboard"
        fieldTwo="Upcoming Live CME (Continuing Medical Education)"
        fieldThree="CME (Continuing Medical Education) Detail"
      />

      <ContainerWrapper>
        <DetailsComponent
          hideThumbNail={true}
          title={meetData.title}
          infoArray={infoArray}
          module="CME (Continuing Medical Education)"
          badgeClassName="bg-cme"
          detailsTitle="Meeting Agenda"
          detailsDescription={meetData.description}
          facultyTitle={"Hosted By"}
          facultyName={
            meetData?.user_details?.firstName +
            " " +
            meetData?.user_details?.lastName
          }
          facultyInstitution={
            meetData?.user_details?.institution?.instituteName
          }
          buttonText={`${getButtonText(meetData)} `}
          buttonAction={() => {
            handleActionButton(meetData?.user_details?._id);
          }}
          hideActionButton={!meetData.joinUrl && !meetData.startUrl}
          profilePicture={meetData.user_details?.profilePicture}
          facultyId={meetData?.user_details?._id}
          fileUrl={meetData?.attachment}
          hideShareField={true}
          isHost={false}
          // isTimeOver={ isMeetingEnd(meetData.endDate) }
          isVisible={true}
          footerButton={renderFooterButton()}
          confUrl={meetData.joinUrl}
          venue={"https://surgicshare.com"}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default CmeRtmDetails;
