import React, { useEffect, useState, useRef } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Card, Col, Form as ReactForm, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createMeeting,
  getMeetingDetails,
  editMeeting,
  checkUserInZoom,
} from "../../../redux/actions/meeting.action";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { read, utils } from "xlsx";
import { getDate } from "../../../utils/core";
import Tagify from "@yaireo/tagify";
import "@yaireo/tagify/dist/tagify.css";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";
import { type } from "@testing-library/user-event/dist/type";

const ScheduleCmeRtm = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [file, setFile] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [agendaFile, setAgendaFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const meetingInfo = useSelector((state) => state.meeting.meetingDetails);

  const { id, associationId } = useParams();
  const fileInputRef = useRef(null);

  useEffect(() => {
    var input = document.querySelector("input[name=memberInput");
    const tagify = new Tagify(input);

    // Add an event listener for the Enter key
    input.addEventListener("keydown", (event) => {
      if (event.key === "Enter" && !tagify.state.dropdown.visible) {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        tagify.addTags(input.value); // Add the tag
        input.value = ""; // Clear the input
      }
    });

    return () => {
      tagify.destroy(); // Clean up Tagify when the component unmounts
    };
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      console.log("edit id", id);
      dispatch(getMeetingDetails({ id: id }));

      setIsEdit(true);
      console.log("edit id", isEdit);
    } else if (associationId !== undefined) {
      // dispatch(checkUserInZoom({ associationId: associationId }, navigate));
    } else {
      // dispatch(checkUserInZoom({}, navigate));
    }
  }, []);

  const handleAgendaFileInputChange = (event) => {
    setAgendaFile(event.currentTarget.files[0]);
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.currentTarget.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (
        fileExtension === "xls" ||
        fileExtension === "xlsx" ||
        fileExtension === "csv"
      ) {
        setFileError(false);
      } else {
        setFileError(true);
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        const fileData = event.target.result;
        const workbook = read(fileData, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

        const firstColumnData = jsonData.map((row) => row[0].toString());

        setFile(firstColumnData);
      };
      reader.readAsBinaryString(selectedFile);
    }
  };

  const initialValues = isEdit
    ? {
        meetingTopic: meetingInfo[0]?.title,
        meetingDate: getDate(meetingInfo[0]?.meetingDate),
        startTime: meetingInfo[0]?.startTime,
        endTime: meetingInfo[0]?.endTime,
        speaker1: meetingInfo[0]?.speaker1,
        speaker2: meetingInfo[0]?.speaker2,
        meetingAgenda: meetingInfo[0]?.description,
        memberInput: meetingInfo[0]?.invitation.join(","),
        memberExcel: null,
        enableReinitialize: true,
      }
    : {
        meetingTopic: "",
        meetingDate: "",
        startTime: "",
        endTime: "",
        speaker1: "",
        speaker2: "",
        meetingAgenda: "",
        memberInput: "",
        memberExcel: null,
        enableReinitialize: true,
      };

  const validationSchema = Yup.object({
    meetingDate: Yup.string().required("Meeting Date is required"),
    meetingTopic: Yup.string().required("Meeting Topic is required"),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string().required("End time is required"),
    meetingAgenda: Yup.string(),
  });

  const getMemberData = (values) => {
    let memberData = [];

    try {
      memberData = JSON.parse(values.memberInput);
    } catch (error) {
      console.error("Error parsing member data:", error);
      // Handle the error appropriately, e.g., set memberData to an empty array
    }

    if (!Array.isArray(memberData)) {
      const stringNumber = memberData.toString();
      memberData = stringNumber.split(",");
    }

    let modifiedData = memberData.map((item) => {
      if (typeof item === "object" && item.hasOwnProperty("value")) {
        return item.value.toString();
      } else {
        return item.toString();
      }
    });
    const mergedArray = [...modifiedData, ...file];
    const result = mergedArray.filter(
      (res) => res !== undefined && res !== null
    );
    return result;
  };

  const handleSubmit = async (values, { resetForm }) => {
    // const payload = {
    //   title: values.meetingTopic,
    //   description: values.meetingAgenda,
    //   meetingDate: values.meetingDate,
    //   startTime: values.startTime,
    //   endTime: values.endTime,
    //   speaker1: values.speaker1,
    //   speaker2: values.speaker2,
    //   members: JSON.stringify([
    //     ...values.memberInput.split(",").map((member) => member.trim()),
    //     ...file,
    //   ]),
    // };
    // if(associationId){
    //   payload.associationId = associationId
    // }

    if (fileError) {
      return;
    }

    let memberData = await getMemberData(values);

    const formData = new FormData();

    formData.append("title", values.meetingTopic);
    formData.append("description", values.meetingAgenda);
    formData.append("meetingDate", values.meetingDate);
    formData.append("startTime", values.startTime);
    formData.append("endTime", values.endTime);
    formData.append("speaker1", values.speaker1);
    formData.append("speaker2", values.speaker2);
    // formData.append(
    //   "members",
    //   JSON.stringify([
    //     ...values.memberInput.split(",").map((member) => member.trim()),
    //     ...file,
    //   ])
    // );
    formData.append("members", JSON.stringify(memberData));
    if (associationId) {
      formData.append("associationId", associationId);
    }

    if (agendaFile) {
      formData.append("attachment", agendaFile);
    }

    if (isEdit) {
      formData.append("id", id);
      dispatch(editMeeting(formData, navigate));
    } else {
      dispatch(createMeeting(formData, navigate));
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setFile([]);
    setAgendaFile(null);
    // resetForm();
  };

  return (
    <Root>
      {associationId ? (
        <Breadcrumb
          title={"CME (Continuing Medical Education)"}
          items={[
            {
              name: "Association Dashboard",
              routeTo: `/AssociationDetails/${associationId}`,
            },
            {
              name: "Organise CME (Continuing Medical Education)",
            },
          ]}
        />
      ) : (
        <Breadcrumb
          title={"CME (Continuing Medical Education)"}
          fieldOne="Dashboard"
          fieldTwo="Organize Live CME"
        />
      )}

      <ContainerWrapper>
        <ProfileComplete />
        <Row>
          <Col xl={10} className="offset-xl-1">
            <Card className="bg-white rounded-3 mb-4">
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div>
                    <h2 className="h4 mb-1">
                      Organize Live CME (Continuing Medical Education)
                    </h2>
                    <p>
                      Fill in the below details and schedule live CME
                      (Continuing Medical Education). Fields with{" "}
                      <span className="text-danger">*</span> are mandatory.
                    </p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    handleSubmit(values, formikBag)
                  }
                >
                  {(formik) => (
                    <Form className="row g-3 g-lg-4">
                      <Col md={4}>
                        <ReactForm.Label for="">
                          Meeting Date<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="date"
                          name="meetingDate"
                          min={getDate(new Date())}
                          placeholder=""
                        />
                        <ErrorMessage
                          name="meetingDate"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label for="">
                          Start Time<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="time"
                          name="startTime"
                          placeholder=""
                        />
                        <ErrorMessage
                          name="startTime"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label for="">
                          End Time<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="time"
                          name="endTime"
                          placeholder=""
                        />
                        <ErrorMessage
                          name="endTime"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label for="">
                          Meeting Topic<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="text"
                          name="meetingTopic"
                          placeholder=" "
                        />
                        <ErrorMessage
                          name="meetingTopic"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label for="">
                          Meeting Agenda<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="textarea"
                          name="meetingAgenda"
                          className="form-control"
                          placeholder=" "
                          rows="4"
                        />
                        <ErrorMessage
                          name="meetingAgenda"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label for="">Speaker 1</ReactForm.Label>
                        <Field
                          className="form-control"
                          type="text"
                          name="speaker1"
                          placeholder=" "
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label for="">Speaker 2</ReactForm.Label>
                        <Field
                          className="form-control"
                          type="text"
                          name="speaker2"
                          placeholder=" "
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label for="">
                          Attach Agenda/ Brochure
                          {/* <span className="text-danger">*</span> */}
                        </ReactForm.Label>
                        <div className="input-group">
                          <input
                            type="file"
                            name="attachment"
                            className="form-control"
                            onChange={handleAgendaFileInputChange}
                            ref={fileInputRef}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label for="">
                          Invite {associationId ? `Additional` : ``} Members
                          Through Mobile No. (India) or E-Mail
                          {/* {associationId ? ( null ) : ( <span className="text-danger">*</span> )} */}
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          name="memberInput"
                          type="text"
                          placeholder=""
                        />
                        <div className="form-text">
                          Enter valid email IDs or mobile no.(India) without the
                          plus symbol or spaces to invite members.
                        </div>
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label for="">
                          Invite {associationId ? `Additional` : ``} Members in
                          Bulk
                          {/* {associationId ? ( null ) : ( <span className="text-danger">*</span> )} */}
                        </ReactForm.Label>
                        <div className="input-group">
                          <input
                            type="file"
                            name="memberExcel"
                            className="form-control"
                            onChange={handleFileInputChange}
                            ref={fileInputRef}
                          />
                        </div>
                        {fileError ? (
                          <p style={{ color: "#f64e60", fontWeight: "600" }}>
                            Only xls, xlsx, csv format is supported
                          </p>
                        ) : null}

                        <div
                          id=""
                          className="form-text"
                          style={{ color: "#f64e60", fontWeight: "600" }}
                        >
                          Excel file should contain only one column of emails or
                          mobile no.(India) without the plus symbol or spaces,
                          without header row.
                        </div>
                      </Col>
                      <Col md={12} style={{ fontWeight: "bold" }}>
                        Note: Meeting should be scheduled at least before 2
                        hours of start of meeting, to check the basic check
                        list.
                      </Col>
                      <Col>
                        <button
                          type="submit"
                          disabled={formik.isSubmitting}
                          className="btn btn-primary mb-2 btn-lg"
                        >
                          Schedule CME &amp; RTM{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default ScheduleCmeRtm;
