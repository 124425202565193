import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";

import { getAllMeetings } from "../../../redux/actions/meeting.action";
import {
  getRecordedConference,
  registerLiveEvents,
} from "../../../redux/actions/conference.action";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import {
  formatDate,
  formattTime,
  timeAgo,
  getStatusFromDate,
  isMeetingEnd,
} from "../../../utils/core";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

import {
  CLEAR_LOADING,
  SET_LOADING,
} from "../../../redux/constants/globalActionTypes";

// import Brochure from "../../../img/live_events/HSICON-2024-SCHEDULE.pdf";

const RecordedVimeoPlay = () => {
  let navigate = useNavigate();
  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  const { eventId } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get("title");
  const code = queryParams.get("code");

  return (
    <Root>
      <Breadcrumb
        title={title}
        fieldOne="Dashboard"
        fieldTwo="Recorded Conference"
      />
      <ContainerWrapper>
        <div className="row">
          <iframe
            width={"100%"}
            height={"500px"}
            src={`https://player.vimeo.com/video/${code}`}
            title="Surgicshare video player"
            frameborder="0"
            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </ContainerWrapper>
    </Root>
  );
};

export default RecordedVimeoPlay;
